@import "../../../styles/main";

$header-background-color: $color-boschung-secondary;
$header-border-color: $color-boschung-main;

.modal-wrapper {
  @include flex-container;
  @include center;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: $sidemenu-width;
  background-color: rgba($color-boschung-secondary-light, 0.8);
  justify-content: center;
  align-items: center;
}

.modal {
  @include flex-column;
  min-width: emCalc(300);
  max-width: emCalc(678);
  max-height: 90vh;
  background-color: $color-boschung-secondary-dark;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: remCalc(24);
    padding: remCalc(10);
    background-color: $header-background-color;
    border-bottom: remCalc(2) solid $header-border-color;
    font-size: emCalc(16);
    text-transform: uppercase;
    color: white;
  }

  &__content {
    @include flex-container;
    padding: emCalc(20) emCalc(40);
    text-align: justify;
    color: white;
  }

  &__footer {
    @include flex-row;
    justify-content: center;
    padding: emCalc(10);
    border-top: emCalc(1) solid $header-border-color;
    color: white;
  }

  &__centered {
    margin: auto;
    margin-bottom: emCalc(15);
  }

  &__text {
    margin-bottom: emCalc(15);
    display: flex;

    &__tabbed {
      margin-left: emCalc(15);
    }
  }
}
