// @use '@boschung/common-react-components/lib/main.css' as boschung;

// Import globals
@import 'base';

// Reset css
@import 'reset';

// Files import
@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

// FIXME: The following is broken because the project is in Typescript and the style from the lib of components is somehow not applied

.bo_button {
  margin: 12px // FIXME: Style from the @boschung lib is not applied when using typescript
}

.MuiFormLabel-root {
  margin: 0.125em
}

.MuiInputBase-root {
  margin: 0.625em
}

// TODO: Import all components styles -> Use sass global importer lib and override webpack config (craco)

.flex-container {
  @include flex-container;

  &__column {
    @include flex-column;
  }

  &__row {
    @include flex-row;
  }

  &__center {
    @include flex-container;
    @include center;
  }
}

.left {
  @include flex-column;
  float: left;
  width: 50%;
  margin-right: 1.625em;
}

.right {
  @include flex-column;
  float: right;
  width: 50%;
  margin-left: 1.625em;
}

.center {
  @include center;
}

.expand {
  flex-grow: 1;
  flex-shrink: 0;
}

.shrink {
  flex-grow: 0;
  flex-shrink: 1;
}

.full-width {
  box-sizing: border-box;
  width: 100%;
}

.full-height {
  box-sizing: border-box;
  height: 100%;
}

.half-width {
  box-sizing: border-box;
  width: 50%;
}

.half-height {
  box-sizing: border-box;
  height: 50%;
}

.card {
  @include card;
}

.card-mini {
  @include card-mini;
}

.text {
  @include text;
}
