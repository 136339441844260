@import "../../../../../styles/main";

.config-form {
  @include card;
  max-width: 768px;
}

.config-subform {
  @include card-mini;
}

.config-button {
  box-sizing: border-box;
  width: 50%;
  margin: 12px;
}

.config-save {
  margin-top: auto;
}

.config {
  flex-grow: 0 !important;
  padding: 1.25em 0.625em;
}
