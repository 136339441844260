@import '../../../../../styles/colors';
@import '../../../../../styles/functions';
@import '../../../../../styles/mixins';

.intervention-table-container {
  @include card($color-boschung-secondary-dark);
  padding: 1.5625em;
  margin: 1.625em 1.25em;
  border-radius: 1.5em;
  box-shadow: 20px 20px 2em rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  align-items: center;
  justify-items: center;
  text-align: center;
  text-justify: auto;

  &__title {
    color: $color-boschung-main;
    font-size: 24px;
    margin-bottom: 0.625em;
  }
}
