@import "../../../../../styles/main";

.option {
  margin-left: 4rem;
}

.option__left {
  float: left;
  min-width: 20%;
  flex-shrink: 1;
}

.option__right {
  float: right;
  flex-grow: 1;
}
