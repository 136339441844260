@import "../../../../../styles/main";

.scheduler-container {
  @include card($color-boschung-secondary-dark);
  flex-direction: row;
}

.scheduler-subform {
  @include flex-column;
  @include card-mini;
  flex-grow: 1;
  width: 100%;
  margin: 0.625em;
}

.scheduler-left {
  @include flex-column;
  @include card-mini;
  float: left;
  width: 50%;
  max-width: 50%;
  margin-right: 1.625em;
}

// CRON Styling
.form-group {
  @include flex-row;
  flex-wrap: nowrap;
  width: 100%;
}

.c-segment {
  @include flex-column;
}

.c-and-list {
  @include flex-row;
  flex-wrap: wrap;
}

.c-and-weekday-list {
  @include flex-row;
  flex-wrap: wrap;
}

.c-and-monthday-list {
  @include flex-row;
  flex-wrap: wrap;
}
