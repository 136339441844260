@import "colors";

/* Colors */
@mixin disableColor($rgbColor) {
  background-color: rgba($rgbColor, 0.5);
}

@mixin flex-container() {
  display: flex;
  box-sizing: border-box;
}

@mixin flex-row() {
  @include flex-container;
  flex-direction: row;
}

@mixin flex-column() {
  @include flex-container;
  flex-direction: column;
}

@mixin center() {
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

@mixin card($backgroundColor: $color-boschung-secondary-dark) {
  @include flex-column;
  flex-grow: 1;
  box-sizing: border-box;
  height: 100%;
  padding: 1.5625em;
  margin: 1.625em 1.25em;
  box-shadow: 20px 20px 2em rgba(0, 0, 0, 0.15);
  border-radius: 1em;
  border-width: 1px;
  border-color: $color-boschung-main;
  border-style: solid;
  background-color: $backgroundColor;
}

@mixin card-mini() {
  box-sizing: border-box;
  padding: 0.625em;
  box-shadow: 0 0 2em rgba(0, 0, 0, 0.3);
}

/* Scrollbar */
@mixin scrollable(
  $xValue: hidden,
  $yValue: scroll,
  $trackColor: $color-boschung-secondary-light,
  $thumbColor: $color-boschung-secondary
) {
  overflow-x: $xValue;
  overflow-y: $yValue;

  /* Scrollbar */
  &::-webkit-scrollbar {
    width: emCalc(10);
  }

  /* The track (progress bar) */
  &::-webkit-scrollbar-track {
    background: $trackColor;
  }

  /* The draggable scrolling handle */
  &::-webkit-scrollbar-thumb {
    background: $thumbColor;

    &:hover {
      background: $color-boschung-secondary-dark;
    }
  }
}

@mixin text(
  $fontSize: remCalc(20),
  $fontColor: white,
  $textAlign: left,
) {
  font-family: Arial, sans-serif;
  font-size: $fontSize;
  font-style: normal;
  font-variant: all-small-caps;
  color: $fontColor;
  text-decoration: none;
  text-align: $textAlign;
}
