@import 'functions';

/*
 * Colors with #hexValue were previous colors from Yann's palette.
 *
 * USAGE: import colors from 'path/to/this/file.scss'
 * colors.whateverColorYouWant
 */

$color-boschung-main: toRGB(#FFCC00);
$color-boschung-main-darkest: darken($color-boschung-main, 45%); //#??????
$color-boschung-main-darker: darken($color-boschung-main, 30%); //#9B7D00
$color-boschung-main-dark: darken($color-boschung-main, 15%); //#C69F00
$color-boschung-main-light: lighten($color-boschung-main, 15%); //#FFD839
$color-boschung-main-lighter: lighten($color-boschung-main, 30%); //#FFE064
$color-boschung-main-lightest: lighten($color-boschung-main, 45%); //#FFFCEA

$color-boschung-secondary: toRGB(#787878);
$color-boschung-secondary-darkest: darken($color-boschung-secondary, 45%);
$color-boschung-secondary-darker: darken($color-boschung-secondary, 30%); //#444444
$color-boschung-secondary-dark: darken($color-boschung-secondary, 15%); //#707070
$color-boschung-secondary-light: lighten($color-boschung-secondary, 15%); //#A9A8A8
$color-boschung-secondary-lighter: lighten($color-boschung-secondary, 30%); //
$color-boschung-secondary-lightest: lighten($color-boschung-secondary, 45%); //

$color-boschung-success: toRGB(#4DDE00);
$color-boschung-success-darkest: darken($color-boschung-success, 45%);
$color-boschung-success-darker: darken($color-boschung-success, 30%); //#2F8700
$color-boschung-success-dark: darken($color-boschung-success, 15%); //#3CAB00
$color-boschung-success-light: lighten($color-boschung-success, 15%); //#6FE032
$color-boschung-success-lighter: lighten($color-boschung-success, 30%); //#8BE65A
$color-boschung-success-lightest: lighten($color-boschung-success, 45%);

$color-boschung-error: toRGB(#FF0000);
$color-boschung-error-darkest: darken($color-boschung-error, 45%);
$color-boschung-error-darker: darken($color-boschung-error, 30%); //#9B0000
$color-boschung-error-dark: darken($color-boschung-error, 15%); //#C50000
$color-boschung-error-light: lighten($color-boschung-error, 15%); //#FF3939
$color-boschung-error-lighter: lighten($color-boschung-error, 30%); //#FF6363
$color-boschung-error-lightest: lighten($color-boschung-error, 45%);

:export {
  boschungMainColor: $color-boschung-main;
  boschungMainDarkestColor: $color-boschung-main-darkest;
  boschungMainDarkerColor: $color-boschung-main-darker;
  boschungMainDarkColor: $color-boschung-main-dark;
  boschungMainLightColor: $color-boschung-main-light;
  boschungMainLighterColor: $color-boschung-main-lighter;
  boschungMainLightestColor: $color-boschung-main-lightest;
  boschungSecondaryColor: $color-boschung-secondary;
  boschungSecondaryDarkestColor: $color-boschung-secondary-darkest;
  boschungSecondaryDarkerColor: $color-boschung-secondary-darker;
  boschungSecondaryDarkColor: $color-boschung-secondary-dark;
  boschungSecondaryLightColor: $color-boschung-secondary-light;
  boschungSecondaryLighterColor: $color-boschung-secondary-lighter;
  boschungSecondaryLightestColor: $color-boschung-secondary-lightest;
  boschungSuccessColor: $color-boschung-success;
  boschungSuccessDarkestColor: $color-boschung-success-darkest;
  boschungSuccessDarkerColor: $color-boschung-success-darker;
  boschungSuccessDarkColor: $color-boschung-success-dark;
  boschungSuccessLightColor: $color-boschung-success-light;
  boschungSuccessLighterColor: $color-boschung-success-lighter;
  boschungSuccessLightestColor: $color-boschung-success-lightest;
  boschungErrorColor: $color-boschung-error;
  boschungErrorDarkestColor: $color-boschung-error-darkest;
  boschungErrorDarkerColor: $color-boschung-error-darker;
  boschungErrorDarkColor: $color-boschung-error-dark;
  boschungErrorLightColor: $color-boschung-error-light;
  boschungErrorLighterColor: $color-boschung-error-lighter;
  boschungErrorLightestColor: $color-boschung-error-lightest;
}
