@import "../../../../../styles/main";

.editor-form {
  @include card($color-boschung-secondary-dark);
  flex-direction: row;

  &__header {
    @include flex-row;
    @include center;
    @include card-mini;
    padding: 1.625em;
    flex-grow: 0;
    height: min-content;
  }

  &__content {
    @include flex-column;
    @include card-mini;
    flex-grow: 1;
    margin: 1.625em 0;
    padding: 1.625em;
  }

  &__footer {
    @include flex-row;
    @include center;
    @include card-mini;
    padding: 0.625em;
    height: min-content;
    flex-grow: 0;
    flex-shrink: 1;
  }

  &__button {
    @include flex-container;
    flex-grow: 1;
    margin: 0 0.625em;
  }
}

.third {
  width: 33%;
}
