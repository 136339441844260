@import '../../../styles/_colors';
@import '../../../styles/_functions';
@import '../../../styles/mixins';

.sidemenu {
  @include flex-column;
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 20000; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  height: 100%;
  width: $sidemenu-width;
  background-color: $color-boschung-secondary-dark;
  max-width: 100%;
  box-shadow: 20px 20px 2em rgba(0, 0, 0, 0.15);
  box-sizing: border-box;

  &-header {
    &__image {
      box-sizing: border-box;
      padding: remCalc(24);
      width: $sidemenu-width;
      height: auto;
      object-fit: contain;
    }
  }

  &-body {
    @include flex-column;
    flex-grow: 1;
  }

  &-footer {
    @include text(remCalc(16), $color-boschung-main, center);
    padding: 1.625em 0.9375em;
  }

  &-link {
    @include text;
    @include card-mini;
    padding: 1.625em 0.9375em;
    max-width: 100%;
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    background-color: $color-boschung-secondary-dark;
    z-index: 10;

    &:hover {
      z-index: 11;
      background-color: $color-boschung-secondary;
      box-shadow: 0 0 4em rgba(255, 200, 0, 0.3);
    }

    &__active {
      border-left: 4px solid $color-boschung-main;
    }
  }
}
