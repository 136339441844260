@import "../../../../../styles/main";

.bo_login-form {
  @include flex-column;
  padding: remCalc(30);
  background-color: $color-boschung-secondary-lightest;
  border-radius: remCalc(5);
  border-width: remCalc(1);
  border-color: $color-boschung-main;
}
