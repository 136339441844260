// TODO: Remove this when all components are imported in main.scss
@import '../../../styles/colors';
@import '../../../styles/mixins';

.container {
  height: 100%;
  background-color: $color-boschung-secondary;
  padding: 1.625em;
}

.login-module {
  @extend .container;
  @include flex-row;
  flex-grow: 1;
  min-height: 0;
  transition: width, left, right, 0.3s;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 760px) {
    margin-left: 80px;
  }
}

.app-module {
  @extend .container;
  @include flex-row;
  flex-grow: 1;
  min-height: 0;
  margin-left: $sidemenu-width;
  transition: width, left, right, 0.3s;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 760px) {
    margin-left: 80px;
  }
}
