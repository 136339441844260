// Base
html, body {
  margin: 0;
  height: 100vh;
}

// Application wrapper
.app {
  height: 100%;
}

// Application module
.app-container {
  @extend .app;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  min-height: 0;
  // Margin for navbar are to be set here
  transition: width, left, right, 0.3s;
  z-index: 0;
}
